/* eslint-disable */
import actionTypes from '../types/common'
import { Map } from 'immutable'

const initialState = Map({
  showSidebar: true,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_TOGGLE:
      return state.set('showSidebar', action.payload.showSidebar)
    default:
  }

  return state
}
