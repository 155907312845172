import React, { useContext } from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const CoinContext = React.createContext({
  isLoaded: false,
  allCoins: [],
  selectedCoin: null,
  setSelectedCoin: (coinId) => {},
})

const CoinProvider = ({ children }) => {
  const [coins, setCoins] = useState([])
  const [selectedCoin, setSelectedCoin] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setCoins([
      {
        _id: '1',
        name: 'CEG',
      },
      {
        _id: '2',
        name: 'CBLI',
      },
    ])

    setIsLoaded(true)
  }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!selectedCoin) {
      if (coins.length) {
        setSelectedCoin(coins[0])
      } else {
        setSelectedCoin(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coins])

  return (
    <CoinContext.Provider
      value={{
        isLoaded,
        allCoins: coins,
        selectedCoin,
        setSelectedCoin,
      }}
    >
      {children}
    </CoinContext.Provider>
  )
}

CoinProvider.propTypes = {
  children: PropTypes.node,
}

export const useCoinContext = () => {
  return useContext(CoinContext)
}

export const useCoinUpdation = (fetchData) => {
  const coinContext = useCoinContext()

  useEffect(() => {
    if (coinContext.selectedCoin && coinContext.isLoaded) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinContext.selectedCoin, coinContext.isLoaded])

  return {}
}

export default CoinProvider
