let modeType = window.location.hostname.includes('localhost')
  ? 'Live'
  : window.location.hostname.includes('dev')
  ? 'Dev'
  : 'Live'

let configURl = {}
if (modeType == 'Dev') {
  configURl = {
    BaseURL: 'https://dev-apis.solosfi.com/api',
    BaseURLImg: 'https://dev-apis.solosfi.com/uploads/',
    UserData: 'solos-admin-data',
  }
} else {
  configURl = {
    BaseURL: 'https://apis.solosfi.com/api',
    BaseURLImg: 'https://apis.solosfi.com/uploads/',
    UserData: 'solos-admin-data',
  }
}

configURl.admin_private_key = '0xa36d728d6ba27298c3ffa59390d70cee10cfdd2baa42c536fdea18bc10bc7eee'
configURl.polygon_mainnet_provider =
  'https://polygon-mainnet.g.alchemy.com/v2/rvmpmREwuUjwDRUj3SBmkP6EDR0rSlVo'
configURl.polygon_testnet_provider =
  'https://polygon-mumbai.g.alchemy.com/v2/qRpA5Y4Fbeip7pho1zuwxPLWGdbYEhbH'
configURl.polygon_uri = 'https://ipfs.io/ipfs/QmPTd69QK8srkncPkE2db8Z495iUqQ4KyBP7TNLv7KHF6F'
configURl.ipfs_url = 'https://api.pinata.cloud/pinning/pinFileToIPFS'
configURl.red_contract_address = '0xAeE3C92D4FCFd25D88AE2465A9736B00884319c6'
configURl.usdc_contract_address = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'
configURl.sr_contract_address = '0x310cB7B1700947F25f75080b138E65aAf0729a75'
configURl.admin_wallet_address = '0x42E72b3Bb3a32408D42948C3eFb32B4A56828C8f'
configURl.admin_pinata_key =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkMjM5YTcwOS05YWU3LTQ4NjYtOTdhMC1mYTFhZTQ3NDY1ZDAiLCJlbWFpbCI6InNvbG9zZmlAZHVjay5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiNDY4ZGIwMzRiM2UwMjNlYmNlN2IiLCJzY29wZWRLZXlTZWNyZXQiOiI3YWUzMWE1ZmU3MGE1YmZlZjFkNjM5MGVkZGY3NGU5MmE2NzYxMmM0ZGNhMjJhZmVjNGQwMmIwZjFmZWRmODUwIiwiaWF0IjoxNzAxMDk0ODMyfQ.JZ-wajKzdfXTR5za9fziXzDrtRSnf7f33nXPp19d9nA'

export default configURl
