import configURl from 'src/runtime.config'
import React from 'react'
import momentTimeZone from 'moment-timezone'
import moment1 from 'moment'
const HRNumbers = require('approximate-number')
export const getCurrentMonthDates = () => {
  let a = moment1().startOf('month').format('YYYY-MM-DD')
  let b = moment1().format('YYYY-MM-DD')
  return [a, b]
}
export const moment = (Date) => {
  return momentTimeZone.utc(Date).tz('America/Los_Angeles')
}
export const BASE_URL = configURl.BaseURL
export const DEBOUNCE_TIME = 700
export const UserDataKey = configURl.UserData

export const IsLoggedIn = () => {
  return ReadLocalStorage(UserDataKey) !== undefined
}

export const ValueFromUserData = (key) => {
  let UserData = ReadLocalStorage(UserDataKey)

  if (UserData !== undefined && UserData !== null) {
    UserData = JSON.parse(UserData)
    // console.log(UserData, key)
    return UserData[key]
  } else {
    return ''
  }
}

export const SetLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const ReadLocalStorage = (key) => {
  const result = localStorage.getItem(key)
  if (result === undefined || result === null) {
    return undefined
  } else {
    // console.log(JSON.parse(result).user.databaseName, '>>>>>>>>>>>>>>>>>>>>>>>>>>RESULT RESIULT')
    return result
  }
}

export const RemoveFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}

export const StandardDateFormat = (Date) => {
  return moment(Date).format('MMM DD, YYYY')
}
export const StandardPicketDateFormat = (Date) => {
  return moment1(Date).format('MMM DD, YYYY')
}

export const fullDateFormat = (date) => {
  return (
    <>
      {moment(date).format('MMM DD, YYYY')}
      <br />
      <small>{moment(date).format('h:mm a')}</small>
    </>
  )
}

export const converyTimestampToDate = (date) => {
  const momentObj = momentTimeZone.unix(parseInt(date, 10)).tz('America/Los_Angeles')

  // Format the moment object as a string in "YYYY-MM-DD" format
  // return momentObj.format('YYYY-MM-DD')
  return (
    <>
      {momentObj?.format('MMM DD, YYYY')}
      <br />
      <small>{momentObj?.format('h:mm a')}</small>
    </>
  )
}

export const getMonthDateYear = (date) => {
  return moment(date).format('DD - MMM, YYYY')
}
export const getDOBMonthDateYear = (date) => {
  return moment(date).utc().format('DD - MMM, YYYY')
}

export const downloadFile = (dataurl, filename) => {
  const a = document.createElement('a')
  a.href = dataurl
  a.setAttribute('download', filename)
  a.click()
}

export const readFileAsDataUrl = (file) => {
  return new Promise((res) => {
    const reader = new FileReader()
    reader.onload = () => {
      res(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export const disableScrollOnBody = (canDisable) => {
  const overflow = canDisable ? 'hidden' : 'auto'
  document.getElementsByTagName('body')[0].style.overflow = overflow
}
export const getLocaleMonthDateYear = (dateString) => {
  const gmtDateTime = moment.utc(dateString, '')
  const local = gmtDateTime.local().format('MMMM DD, YYYY')
  return local
}

export const getLocaleTime = (dateString) => {
  const gmtDateTime = moment.utc(dateString, '')
  const local = gmtDateTime.local().format('hh:mm A')
  return local
}

export const getSearchQuery = (query, name) => {
  const urlSearcher = new URLSearchParams(query)
  return urlSearcher.get(name)
}

export const getFilterMonthDateYear = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const humanReadableNumber = (number) => {
  return HRNumbers(number, { capital: true })
}
export const formatNumberInUSD = (num) => {
  return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}
