import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import '../src/css/style.css'
import './scss/common.scss'
import ClipboardJS from 'clipboard'
import 'bootstrap-daterangepicker/daterangepicker.css'
import CoinProvider from './providers/CoinProvider'
import Router from './Router'
import useInit from './hooks/useInit'
import 'suneditor/dist/css/suneditor.min.css'

new ClipboardJS('.copy-text')
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-puls`e"></div>
  </div>
)

const App = () => {
  document.title = 'SOLOS - Admin Panel'
  useInit()

  return (
    <Suspense fallback={loading}>
      <BrowserRouter>
        <CoinProvider>
          <Router />
        </CoinProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
