import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { common } from './reducers'
import modules from './types/modules'

const store = configureStore({
  reducer: {
    [modules.COMMON]: common,
  },
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
